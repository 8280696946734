import Vue from 'vue'
import VueRouter from 'vue-router'

import AppLayout from "../components/Layout/AppLayout.vue"
import AuthLayout from "../components/Layout/AuthLayout.vue"

Vue.use(VueRouter)

const routes = [
	/*{
		path: "*",
		redirect: "/login",
	},*/
	{
		path: "/login",
		redirect: "/login",
		component: AuthLayout,
		children: [
			{
				name: "login",
				path: "",
				component: () => import( /* webpackChunkName: "Login" */ '../views/Login.vue')
			}
		],
	},
	{
		path: "/",
		redirect: "/dashboard",
		component: AppLayout,
		children: [
			{
				name: "dashboard",
				path: "dashboard",
				component: () => import( /* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue')
			},
			{
				name: "request",
				path: "request",
				component: () => import( /* webpackChunkName: "Dashboard" */ '../views/Request.vue')
			}
		],
	},
]

const router = new VueRouter({
	routes
})

export default router