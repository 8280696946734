<template>
	<div id="app">
		<router-view id="app-router-view" />
	</div>
</template>

<script>
// @ is an alias to /src
import HeaderIFood from '@/components/Header.vue'
import FooterIFood from '@/components/Footer.vue'

require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
  name: 'Home',
  components: {
    HeaderIFood,
	FooterIFood
  }
}
</script>

<style lang="scss">
	#app-router-view {
		height: 100%;
	}
</style>
