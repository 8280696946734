<template>
  <div class="h-100 row align-items-center m-0">
    <div class="col d-flex justify-content-center">
      <div class="box">
        <div class="text-center mb-4">
          <img src="../assets/svg/logo-red.svg" alt="" />
          <p class="h2 mt-5">Faça login para continuar</p>
        </div>
        <form v-on:submit.prevent="login()">
          <b-form-group
            id="input-group-email"
            label="Informe o seu e-mail:"
            label-for="input-email"
          >
            <b-form-input
              id="input-email"
              v-model="form.email"
              type="email"
              placeholder="Digite seu e-mail"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-password"
            label="Informe a sua senha:"
            label-for="input-password"
          >
            <b-form-input
              id="input-password"
              v-model="form.password"
              type="password"
              placeholder="Digite sua senha"
              required
            ></b-form-input>
          </b-form-group>

          <div class="text-center mb-3">
            <a href="#">Esqueci minha senha</a>
          </div>

          <b-button class="center" type="submit" variant="primary">Entrar</b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      axios
        .post("https://api.ifoodreembolsoag.com/prd/auth/login", {
          username: this.form.email,
          password: this.form.password,
        })

        .then((response) => {
          localStorage.setItem("user", encodeURI(JSON.stringify(response.data.user)));
          localStorage.setItem("token", response.data.idToken);
          localStorage.setItem("refresh", response.data.refreshToken);
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          console.log(error);
          this.message = "Favor verificar os campos.";
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 510px;
  padding: 40px 60px;
}
a {
  color: $red;
}
.btn {
  padding: 0.8rem !important;
}
</style>
