<template>
    <div id="app-layout" class="">

        <iFoodHeader />

        <router-view class="container mt-5 pt-5"></router-view>

    </div>
</template>

<script>
    import iFoodHeader from "../Header.vue";
    import iFoodFooter from "../Footer.vue";

    export default {

        name: "app-layout",
        props: {},
        components: {
            iFoodHeader,
            iFoodFooter
        },
        data() {
            return {
            }
        },
        methods: {},

    }
</script>

<style lang="scss">
.content {
    padding-top: 8rem;
}
</style>
