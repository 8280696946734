<template>
  <div>
    <div id="filter" class="mb-5">
      <div class="row">
        <div class="col-12">
          <div class="firula">
            <div class="row">
              <div class="col align-self-center text-center">
                <span class="text-primary">Filtar ({{ total }}):</span>
              </div>
              <div class="col-4">
                <b-form-datepicker
                  v-model="filter.createdAt"
                  @input="debounceSearch"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="pt-br"
                  label-no-date-selected="Por data"
                ></b-form-datepicker>
              </div>
              <div class="col-4">
                <b-form-input
                  v-model="filter.id"
                  @input="debounceSearch"
                  placeholder="Solicitação"
                ></b-form-input>
              </div>
              <div class="col-2">
                <b-form-select
                  id="status-3"
                  v-model="filter.status"
                  @change="debounceSearch()"
                >
                  <b-form-select-option value="">Por status</b-form-select-option>
                  <b-form-select-option value="invalid">Invalido</b-form-select-option>
                  <b-form-select-option value="hold">Em Aberto</b-form-select-option>
                  <b-form-select-option value="success">Sucesso</b-form-select-option>
                </b-form-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pr-0 mt-3">
        <div class="col-4 offset-8 text-right">
          <b-button-group>
            <b-button
              v-if="!importData"
              @click="importData = true"
              style="margin-right: 5px"
              >Importar Dados</b-button
            >
            <vue-csv-import
              v-if="importData"
              v-model="csv"
              :autoMatchFields="true"
              :autoMatchIgnoreCase="true"
              :map-fields="['id', 'totalNFe', 'reason', 'status']"
            >
            </vue-csv-import>
          </b-button-group>

          <b-button-group>
            <b-button v-if="!nfe" @click="liberarExcel()">Exportar Dados</b-button>
            <export-excel
              v-if="liberarExcel && nfe"
              class="btn btn-secondary"
              :data="nfe"
              type="xls"
              :name="'Reembolsos.xls'"
            >
              <img src="@/assets/img/excel.png" style="width: 30px; margin-left: 6px" />
              Download
            </export-excel>
          </b-button-group>
        </div>
      </div>
    </div>
    <div v-if="csv">
      <div style="padding-top: 50px">
        <p>Results</p>
        <table class="table table-striped">
          <thead class="thead-red">
            <tr>
              <th scope="col">id</th>
              <th scope="col">Status</th>
              <th scope="col">Motivo</th>
              <th scope="col">Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in csv" :key="index" v-if="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.reason }}</td>
              <td>{{ item.totalNFe }}</td>
            </tr>
          </tbody>
        </table>
        <b-button v-if="!nfe" @click="confirmImport()">Confirmar Importação</b-button>
      </div>
    </div>
    <b-overlay :show="loading" rounded="sm">
      <table class="table table-striped">
        <thead class="thead-red">
          <tr>
            <th scope="col">Solicitação</th>
            <th scope="col">Data</th>
            <th scope="col">Id. Solicitante</th>
            <th scope="col">Nº da nota</th>
            <th scope="col">Valor</th>
            <th scope="col">Aprovação</th>
            <th scope="col">Status</th>
            <th scope="col">Ver</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in nfs" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.createdAt | moment("DD/MM/YYYY") }}</td>
            <td>{{ item.uploaded_by }}</td>
            <td v-if="item.user_input">{{ item.user_input.nf }}</td>
            <td v-else></td>
            <td v-if="item.user_input">R$ {{ totalValue(item) }}</td>
            <td v-else></td>
            <td>{{ item.status }}</td>
            <td>{{ item.found }}</td>
            <td>
              <b-button @click="goToRequest(item)" variant="transparent">
                <b-icon icon="eye"></b-icon>
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </b-overlay>

    <div class="text-center">
      <b-button-group>
        <b-button variant="light" v-if="page > 1" @click="goTo(-1)">Anterior</b-button>
        <b-button variant="light" v-if="page > 1" @click="goTo(-1)">{{
          page - 1
        }}</b-button>
        <b-button variant="light">{{ page }}</b-button>
        <b-button variant="light" v-if="next" @click="goTo(1)">{{ page + 1 }}</b-button>
        <b-button variant="light" v-if="next" @click="goTo(1)">Próxima</b-button>
      </b-button-group>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { VueCsvImport } from "vue-csv-import";
const serialize = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export default {
  name: "Login",
  components: {
    VueCsvImport,
  },
  data() {
    return {
      csv: null,
      loading: true,
      nfs: null,
      importData: false,
      next: false,
      total: 0,
      fileCsv: null,
      nfe: null,
      page: 1,
      status: "hold",
      debounce: null,
      filter: {
        createdAt: "",
        id: "",
        status: "hold",
      },
      form: {
        email: "",
        password: "",
      },
    };
  },
  async mounted() {
    this.loading = true;
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    try {
      var { data } = await axios.get(
        "https://api.ifoodreembolsoag.com/prd/nf/all?status=" + this.status,
        config
      );
      this.total = data.total;
      if (this.total > 10) this.next = true;
      this.nfs = data.items;
      this.loading = false;
    } catch (error) {
      this.$router.push("/login");
      this.loading = false;
    }
  },
  methods: {
    confirmImport() {
      const config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      for (var i = 0; i < this.csv.length; i++) {
        if (this.csv[i].id) {
          axios
            .put(
              "https://api.ifoodreembolsoag.com/prd/nf/" + this.csv[i].id,
              {
                status: this.csv[i].status,
                reason: this.csv[i].reason,
              },
              config
            )

            .then((response) => {
              localStorage.setItem("user", encodeURI(JSON.stringify(response.data.user)));
              localStorage.setItem("token", response.data.idToken);
              localStorage.setItem("refresh", response.data.refreshToken);
              this.$router.push("/dashboard");
            })
            .catch((error) => {
              console.log(error);
              this.message = "Favor verificar os campos.";
            });
        }
      }
    },
    totalValue(e) {
      let tempValue = 0;
      e.user_input.products.forEach((element) => {
        tempValue = element.value * element.quantity + tempValue;
      });
      return tempValue.toFixed(2);
    },
    async liberarExcel() {
      const config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      var data_full = await axios.get(
        `https://api.ifoodreembolsoag.com/prd/nf/all?${serialize(this.filter)}&status=` +
          this.status +
          "&limit=2000",
        config
      );

      let nfe = data_full.data.items;
      for (var a = 0; a < nfe.length; a++) {
        nfe[a].totalNfe = 0;
        nfe[a].nf = nfe[a].user_input.nf;
        nfe[a].cnpj = nfe[a].user_input.cnpj;
        nfe[a].product = nfe[a].user_input.products[0].name;
        nfe[a].qtd = nfe[a].user_input.products[0].quantity;
        for (var c = 0; c < nfe[a].user_input.products.length; c++) {
          nfe[a].totalNfe = nfe[a].totalNfe + (nfe[a].user_input.products[c].value * nfe[a].user_input.products[0].quantity);
        }
        if (nfe[a].totalNfe > 18) nfe[a].totalNfe = 18;
        this.nfe = nfe;
      }
    },
    debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        this.loading = true;

        this.page = 0;

        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };

        this.page = this.page;

        var { data } = await axios.get(
          `https://api.ifoodreembolsoag.com/prd/nf/all?${serialize(this.filter)}&offset=${
            this.page * 10
          }`,
          config
        );

        this.total = data.total;
        if (this.total > this.page * 10) {
          this.next = true;
        } else {
          this.next = false;
        }
        this.nfs = data.items;
        this.loading = false;
      }, 600);
    },
    async goTo(item) {
      this.loading = true;
      const config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      this.page = this.page + item;

      var { data } = await axios.get(
        `https://api.ifoodreembolsoag.com/prd/nf/all?${serialize(this.filter)}&offset=${
          this.page * 10
        }`,
        config
      );
      if (this.total > this.page * 10) {
        this.next = true;
      } else {
        this.next = false;
      }
      this.nfs = data.items;
      this.loading = false;
    },
    goToRequest(item) {
      localStorage.setItem("note", JSON.stringify(item));
      this.$router.push("/request");
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  .thead-red {
    th {
      color: $white;
      background-color: $red;
      border-color: $red;
    }
  }
  tr {
    background-color: $white;
    color: #3f3e3e;
    font-size: 0.9rem;
    td {
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: middle;
    }
    td:last-child {
      .btn {
        padding: 0;
        font-size: 1.2rem;
        font-weight: bold;
        color: $red;
      }
    }
  }
}

.btn {
  &.btn-light {
    color: #a6a29f;
    border: 1px solid #dee2e6 !important;
  }
}
</style>
