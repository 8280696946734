import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueLazyLoad from 'vue-lazyload'

import axios from 'axios'

import vuescroll from 'vuescroll';

import Maska from 'maska'
import money from 'v-money'


import excel from 'vue-excel-export'

Vue.use(excel)

Vue.config.productionTip = false
Vue.prototype.api = ''

Vue.use(money, {precision: 4})
Vue.use(Maska)
Vue.use(vuescroll, {});
Vue.use(VueLazyLoad)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(require('vue-moment'));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
