<template>
    <header>
        <div class="container">
            <div class="row">
                <div class="col">Reembolso</div>
                <div class="col">
                    <img src="../assets/svg/logo.svg" alt="" />
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<style lang="scss" scoped>
header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $red;
    font-family: "SulSans";
    font-weight: 100;
    font-size: 1.5rem;
    padding: 0.782rem;
    z-index: 9;
    .container {
        .row {
            justify-content: space-evenly;
            align-items: center;
            div {
                color: $white;
                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}
</style>
