<template>
	<div v-if="nf">
        <div class="row">
            <div class="col-8" >
                <div class="text-center">
                    <h1>Solicitação {{nf.id}}</h1>
                </div>
                <p>Nº da nota fiscal: <span v-if="nf.user_input">{{nf.user_input.nf}}</span></p>
                <p>Data da compra: {{nf.createdAt | moment("DD/MM/YYYY")}}</p>
                <p v-for="(item, index) in nf.user_input.products" :key="index">
                    <span class="text-primary">Produto {{index + 1}}</span>
                    <ul>
                        <li>Produto: {{item.name}}</li>
                        <li>Valor unitário: R$ {{item.value}}</li>
                        <li>Quantidade: {{item.quantity}}</li>
                        <li>Valor total: {{item.quantity*item.value}}</li>
                    </ul>
                </p>
                
                
                <p>Valor maximo reembolso: R$ 18,00</p>
                <hr>
                <p>Solicitante: {{nf.uploaded_by}}</p>
                <p>Aprovação: {{nf.found}}</p>
                <p>Status: {{nf.status}}</p>
            </div>
            <div class="col">
                <div class="image-box">
                    <div class="search-box">
                        <b-icon icon="search"></b-icon>
                    </div>
                    <LightBox ref="lightbox" :media="media" :show-caption="false" :show-thumbs="false" :show-light-box="false"></LightBox>
                    <img class="img-fluid" @click="openGallery(0)" :src="blob" alt="">
                </div>
            </div>
        </div>
        <div class="text-center">
            <b-button :to="'/dashboard'" variant="primary">Voltar</b-button>
        </div>
	</div>
</template>

<script>
import axios from "axios";
import LightBox from 'vue-image-lightbox'
export default {
    components: {
        LightBox,
    },
    data(){
        return {
            nf: null,
            blob: null,
            media: [{ // For image
                thumb: 'https://fdr.com.br/wp-content/uploads/2020/06/nota_fiscal_paulista-1200x800.jpg',
                src: 'https://fdr.com.br/wp-content/uploads/2020/06/nota_fiscal_paulista-1200x800.jpg',
            }]
        }
    },
    async created(){
        this.nf = JSON.parse(localStorage.getItem("note"));

        

        const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };

    var {data} = await axios.get("https://api.ifoodreembolsoag.com/prd/nf/" + this.nf.id +"/image", config);
    this.media[0].thumb = data.url
    this.media[0].src = data.url
        this.blob = data.url
        
    },
    methods: {
        openGallery(index) {
            this.$refs.lightbox.showImage(index)
        }
    }
}
</script>

<style lang="scss" scoped>
hr {
    border-top: 1px solid $red;
}
.image-box {
    border: 1px solid $red;
    border-radius: 7px;
    padding: 0.35rem;
    position: relative;
    cursor: pointer;
    .search-box {
        width: 36px;
        height: 36px;
        position: absolute;
        bottom: 0.70rem;
        right: 0.70rem;
        text-align: center;
        line-height: 2.5rem;
        background-color: rgba(255, 255, 255, 0.5);
        font-size: 1rem;
        color: $red;

    }
}
</style>